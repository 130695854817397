import React, { useState } from "react";
import logo from "../../Assets/Images/logo.png";
import LinearBg from "./LinearBg";
import Nav from "./Nav";
import SubCategoryNav from "./SubCategoryNav";

const Navbar = () => {
  return (
    <>
      <LinearBg />
      <Nav />
      <div className="display-nav">
      <SubCategoryNav />
      </div>
    </>
  );
};

export default Navbar;

import React, { useState, useEffect, useContext, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
// import Header from "./components/Navbar/Header";
import HomeRouting from "./Router/HomeRouting";
import Footer from "./components/Footer/Footer";
import axios from "axios";
import { UserContext } from "./Context/UserContext";
// import SecondNavbar from "./SecondNavbar";
import FallbackLoader from "./Assets/FallbackLoader/FallbackLoader";
import Navbar from "./components/Navbar/Navbar";

const App = () => {
  const [user] = useContext(UserContext);
  const [loader, setLoader] = useState(false);

  const [person, setPerson] = useState(null);

  const [viewAll, setViewAll] = useState([]);
  const [topSelling, setTopSelling] = useState([]);
  const [data, setData] = useState({});
  const [officeInfo, setOfficeInfo] = useState();

  useEffect(() => {
    setLoader(true);
    axios({
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-headers": "*",
      },
      url: `${process.env.REACT_APP_BASEURL}/home`,
    })
      .then((res) => {
        let response = res.data.data;
        setData(res.data.data);
        setViewAll(response.products.todaysDeals);
        setTopSelling(response.products.topSellingProducts);
        setOfficeInfo(response.officeInfo);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoader(false);
      });
  }, []);

  return (
    <div>
      <Suspense fallback={<FallbackLoader />}>
      {/* <Header person={user} /> */}
        {/* <SecondNavbar person={user} /> */}
        <Navbar />

        <HomeRouting
          data={data}
          person={person}
          setPerson={setPerson}
          viewAll={viewAll}
          loader={loader}
          topSelling={topSelling}
        />
        <Footer data={officeInfo} loader={loader} />
      </Suspense>
      {/* <div class="dropdown">
        <button
          class="btn btn-primary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-mdb-toggle="dropdown"
          aria-expanded="false"
        >
          Dropdown button
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li>
            <a class="dropdown-item" href="#">
              Submenu &raquo;
            </a>
            <ul class="dropdown-menu dropdown-submenu">
              <li>
                <a class="dropdown-item" href="#">
                  Submenu item 1
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Submenu item 2
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Submenu item 4
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Submenu item 5
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default App;

import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
// import { categoryData } from "../../DummyData/DummyData";

const SubCategoryNav = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASEURL}/getCategoriesList`,
    })
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  console.log("Categories are", categories);

  return (
    <div className="nav-main-categories">
      <div className="container">
        <div className="nav-categories">
          {categories?.slice(0, 5)?.map((cat) => (
            <div className="nav-list">
              <p className="make-up">
                <NavLink
                  to={`/our-categories/${cat.title}`}
                  state={{ id: cat.id }}
                >
                  {cat.title}
                </NavLink>
              </p>
              <div className="make-up-child">
                <div className="d-flex">
                  {cat.children?.map((sub) => (
                    <div className="category-list">
                      <p>
                        <NavLink
                          to={`/our-categories/${sub.title}`}
                          state={{ id: sub.id }}
                        >
                          {sub.title}
                        </NavLink>
                      </p>

                      <ul>
                        {sub?.children?.map((get, keys) => {
                          return (
                            <li className="category-list-li">
                              <p className="category-list-p">
                                <NavLink
                                  to={`/our-categories/${get.title}`}
                                  state={{ id: get.id }}
                                >
                                  {" "}
                                  {get.title}
                                </NavLink>
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ))}

                  {/* <div className="category-list-1">
                    <h3>Eyes</h3>
                    <ul>
                      {categoryData.map((get, keys) => {
                        return (
                          <li className="category-list-li">
                            <p className="category-list-p">{get.name}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="category-list-2">
                    <h3>Eyes</h3>
                    <ul>
                      {categoryData.map((get, keys) => {
                        return (
                          <li className="category-list-li">
                            <p className="category-list-p">{get.name}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          ))}

          {/* <div className="nav-list">
            <p className="make-up">Skincare</p>
            <div className="make-up-child">
              <div className="d-flex">
                <div className="category-list">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="category-list-1">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="category-list-2">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="nav-list">
            <p className="make-up">Haircare</p>
            <div className="make-up-child">
              <div className="d-flex">
                <div className="category-list">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="category-list-1">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="category-list-2">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="nav-list">
            <p className="make-up">Body</p>
            <div className="make-up-child">
              <div className="d-flex">
                <div className="category-list">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="category-list-1">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="category-list-2">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="nav-list">
            <p className="make-up">Essentials</p>
            <div className="make-up-child">
              <div className="d-flex">
                <div className="category-list">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="category-list-1">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="category-list-2">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="nav-list">
            <p className="make-up">Hands&Feet</p>
            <div className="make-up-child">
              <div className="d-flex">
                <div className="category-list">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="category-list-1">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="category-list-2">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="nav-list">
            <p className="make-up">Wellness</p>
            <div className="make-up-child">
              <div className="d-flex">
                <div className="category-list">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="category-list-1">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="category-list-2">
                  <h3>Eyes</h3>
                  <ul>
                    {categoryData.map((get, keys) => {
                      return (
                        <li className="category-list-li">
                          <p className="category-list-p">{get.name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SubCategoryNav;

import React, { useState, createContext, useEffect, useContext } from "react";
import { UserContext } from "./UserContext";
import axios from "axios";

export const wishlistContext = createContext();

export const WishlistProvider = (props) => {
  const [user] = useContext(UserContext);
  const [wishedData, setWishedData] = useState([]);
  const [againfetch, setAgainfetch] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
    if (user) {
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASEURL}/getWishlist`,
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      })
        .then((res) => {
          setLoad(false);
          let response = res.data.data;
          setWishedData(response);
        })
        .catch((err) => {
          setLoad(false);
          console.log(err.message);
        });
    }
  }, [user, againfetch]);


  return (
    <wishlistContext.Provider
      value={[
        wishedData,
        setWishedData,
        againfetch,
        setAgainfetch,
        load,
        setLoad,
      ]}
    >
      {props.children}
    </wishlistContext.Provider>
  );
};

import React from "react";

const DeliveryFooter = () => {
  return (
    <>
      <div className="section">
        <div className="container"> 
          <div className="delivery-footer-main">
          <div className="row">
            <div className="col-6">
              <div className="delivery-footer-content">
                <h5>Free Shipping</h5>
                <h5>on purchase above Rs. 1000</h5>
              </div>
            </div>
            <div className="col-6">
              <div className="delivery-footer-content">
                <h5>Free </h5>
                <h5>Replacement</h5>
              </div>
            </div>
            <div className="col-6">
              <div className="delivery-footer-content">
                <h5>Cash on</h5>
                <h5>Delivery</h5>
              </div>
            </div>
            <div className="col-6">
              <div className="delivery-footer-content">
                <h5>100%</h5>
                <h5>Privacy</h5>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryFooter;

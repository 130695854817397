import React, {useState,useEffect, createContext,useContext} from "react"
import axios from "axios";
import { UserContext } from "./UserContext";

export const CartContext = createContext();

export const CartContextProvider = (props) => {
    const [cartFetch , setCartFetch] = useState([]);
    const [cartCount,setCartCount] = useState([])
    const [refetch,setRefetch]=useState(false)
    const [loading, setLoading] = useState(false)

    const [user] = useContext(UserContext);

    useEffect(() => {
        if(user){
            setLoading(true);
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASEURL}/getCart`,
            headers: {
              Authorization: `Bearer ${user?.token}`, 
            }
        }).then((res)=>{
            let response = res.data.data.cartItems;
            setCartCount(response);
            setCartFetch(res.data.data)
            setLoading(false);

        }).catch((err)=>{
            console.log(err.message)
            setLoading(false);
        })
        }
    },[user,refetch])


    return(
        <CartContext.Provider value={[cartCount,refetch,setRefetch,cartFetch]}>
            {props.children}
        </CartContext.Provider>
    )
}
import React from "react";

const FinalFooter = () => {
  return (
    <>
      <div className="final-footer-main">
        <div className="d-flex justify-content-center gap-4">
          <p>Terms and Conditions</p>
          <p>Privacy Policy</p>
        </div>
        <div className="text-center">
          <p>@2022 forevryng.com All Rights Reserved</p>
        </div>
      </div>
    </>
  );
};

export default FinalFooter;

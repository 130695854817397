import React from 'react'
import DeliveryFooter from './DeliveryFooter'
import FinalFooter from './FinalFooter'
import IntroFooter from './IntroFooter'

const Footer = () => {
  return (
    <>
        <IntroFooter />
        <DeliveryFooter />
        <FinalFooter />
    </>
  )
}

export default Footer
import React, {useEffect ,lazy , Suspense} from 'react';
import { Routes, Route } from 'react-router-dom'

const Home = lazy(() => import('../Home'));
const Detailpage = lazy(() => import('../components/Detailpage/Detailpage'));
const Review = lazy(() => import('../components/MyReviews/Review'));
const Reviewed = lazy(() => import('../components/MyReviews/Reviewed/Reviewed'));
const Tobereview = lazy(() => import('../components/MyReviews/Tobereview/Tobereview'));
const Termsandconditions = lazy(() => import('../components/TermsandConditions/Termsandconditions'))
const Contactus = lazy(() => import('../components/Contactus/Contactus'));
const Categories = lazy(() => import('../components/Contentfor/Category/Categories'))
const Login = lazy(() => import('../components/Contentfor/Login/Login'));
const Signup = lazy(() => import('../components/Contentfor/Signup/Signup'));
const Faq = lazy(() => import('../components/FAQs/Faq'));
const Wishlist = lazy(() => import('../components/Wishlist/Wishlist'));
const Brandall = lazy(() => import('../components/Brands/Brandall'));
const Addtocart = lazy(() => import('../components/AddToCart/Addtocart'));
const Search = lazy(() => import('../components/Search/Search'));
const ViewallToday = lazy(() => import('../components/Contentfor/Viewall/TodayDeals/ViewallToday'));
const ViewallTop = lazy(() => import('../components/Contentfor/Viewall/TopSellingProducts/ViewallTop')) ;     
const Mypoints = lazy(() => import('../components/Mypoints/Mypoints'));
const Forgotpassword = lazy(() => import('../components/Contentfor/Forgotpassword/Forgotpassword'));
const Proceed = lazy(() => import('../components/AddtocartProceed/Proceed'));
const OffersAndPromos = lazy(() => import('../Pages/OffersAndPromos/OffersAndPromos'));
const EditProfile = lazy(() => import('../Pages/EditProfile/EditProfile'));
const ResetPassword = lazy(() => import('../components/Contentfor/ReserPassword/ResetPassword'));
const VerifyToken = lazy(() => import('../components/Contentfor/VerifyToken/VerifyToken'));
const ReferAndEarn = lazy(() => import('../components/Contentfor/ReferAndEarn/ReferAndEarn'));
const UpdateProfile = lazy(() => import('../Pages/EditProfile/UpdateProfile'));
const AddAddress = lazy(() => import('../components/Contentfor/AddAddress/AddAddress'));
const EditAddress = lazy(() => import('../components/Contentfor/AddAddress/EditAddress'));
const AvailableCoupons = lazy(() => import('../components/AddtocartProceed/AvailableCoupons'));
const OurBlogs = lazy(() => import('../Pages/OurBlogs/OurBlogs'));
const OurArticle = lazy(() => import('../Pages/OurBlogs/OurArticle'))


const HomeRouting = (props) => {
  return ( 
    
    <Routes> 
        <Route exact path="/" element={ <Home data={props.data} loader={props.loader} />} />
        <Route exact path="/" element={<Reviewed />} />
        <Route exact path="/" element={<Tobereview />} />
        <Route path="/detailpage/:id" element={<Detailpage/>} />
        <Route path="/reviews" element={<Review />} /> 
        <Route path="/termsandconditions" element={<Termsandconditions />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/our-categories/:title" element={<Categories />} /> 
        <Route path="/loginandsignup" element={<Login user={props.person} setUser={props.setPerson} />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-token" element={<VerifyToken />} />
        <Route path="/frequentlyaskedquestion" element={<Faq />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/our-brands/:title" element={<Brandall />} /> 
        <Route path="/cart" element={<Addtocart/>} /> 
        <Route path="/searchpage" element={<Search/>} />   
        <Route path="/todaysdeal" element={<ViewallToday viewAll={props.viewAll} loader={props.loader} />} /> 
        <Route path="/topsellingproducts" element={<ViewallTop data={props.topSelling} loader={props.loader}/>} /> 
        <Route path="/update-profile" element={<UpdateProfile/>} /> 
        <Route path="/mypoints" element={<Mypoints />} /> 
        <Route path="/forgetpassword" element={<Forgotpassword />} /> 
        <Route path="/proceed" element={<Proceed />} /> 
        <Route path="/proceed/available-coupons" element={<AvailableCoupons />} /> 
        <Route path="/offersandpromos" element={<OffersAndPromos />} /> 
        <Route path="/edit-profile/*" element={<EditProfile />} /> 
        <Route path="/refer-and-earn" element={<ReferAndEarn />} /> 
        <Route path="/add-address" element={<AddAddress />} /> 
        <Route path="/edit-address" element={<EditAddress />} /> 
        <Route path="/our-blogs/:slug" element={<OurBlogs />} /> 
        <Route path="/our-article" element={<OurArticle />} /> 

    </Routes>

  )
}

export default HomeRouting
import React from "react";
import logo from "../../Assets/Images/logo.png";
import mobile from "../../Assets/Images/mobile.png";
import playstore from "../../Assets/Images/playstore.png";
import appstore from "../../Assets/Images/appleStore.png";
import mail from "../../Assets/Images/mail.png";
import mob from "../../Assets/Images/Group.png";

const IntroFooter = () => {
  return (
    <>
      <div className="get-app">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex justify-content-center">
                <div className="mob-image">
                  <img src={mob} alt="" />
                </div>
                <div> 
                  <h4 className="follow-head">EXPERIENCE OUR APP ON</h4>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="get-on-img">
                  <img src={playstore} alt="" />
                </div>
                <div className="get-on-img">
                  <img src={appstore} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div className="follow-contact">
                <div className="d-flex justify-content-center">
                  <div className="mob-image">
                    <img src={mobile} alt="" />
                  </div>
                  <div>
                    <h4 className="follow-head">+977 986598656598</h4>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="mob-image">
                    <img src={mail} alt="" />
                  </div>
                  <div>
                    <h4 className="follow-head">XYZ@foreveryng.com</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="intro-footer-main">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="intro-content">
                <img src={logo} alt="" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="intro-content">
                <h3>Who we are ?</h3>
              </div>
            </div>
            <div className="col-md-3">
              <div className="intro-content">
                <h3>Help</h3>
              </div>
            </div>
            <div className="col-md-3">
              <div className="intro-content">
                <h3>Quick Links</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroFooter;

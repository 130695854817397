import React, { useContext, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../Assets/Images/logo.png";
import { UserContext } from "../../Context/UserContext";
import axios from "axios";

const Nav = () => {
  const [user, setUser] = useContext(UserContext);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const logoutHandler = () => {
    setUser(null);
    localStorage.removeItem("user");
  };

  const searchHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_ENDURL}/autoSearch`,
      params: {
        search_query: search,
      },
    })
      .then((res) => {
        navigate("/searchpage", {
          state: { products: res.data.data.products, query: search },
        });
        // closeModal();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div>
      <nav className="navbar container-fluid navbar-expand-lg">
        <div className="container-fluid">
          <Link to="/">
            <img src={logo} alt="" className="nav-logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <i class="fa-solid fa-bars"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#">
                  Categories
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Brand
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Store
                </a>
              </li>
              <li className="nav-item">
                <NavLink to="/our-article" className="nav-link" href="#">
                  Blog{" "}
                </NavLink>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Community
                </a>
              </li>
            </ul>
            <form class="d-flex">
              <input
                class="form-control me-2 nav-input"
                placeholder="Search for Products"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    searchHandler(e);
                  }
                }}
              />
              {user ? (
                <>
                  <NavLink to="/cart">
                    <button class="nav-btn" type="submit">
                      <i className="fa fa-shopping-cart"></i> My Cart
                    </button>
                  </NavLink>

                  <a
                    href="#"
                    className="nav-link mx-2 my-1"
                    onClick={logoutHandler}
                  >
                    Logout
                  </a>
                </>
              ) : (
                <NavLink to="/loginandsignup">
                  <button class="nav-btn" type="submit">
                    Sign In
                  </button>
                </NavLink>
              )}

              <span className="search-icon">
                <i class="fa-solid fa-magnifying-glass"></i>
              </span>
              <span className="search-icon"></span>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
